import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import { Helmet } from 'react-helmet';
// import '../themes/MobileApp/assets/scripts/app';
import Theme, { theme } from '../themes/MobileApp';
import {
  Navbar,
  Copyright,
  Footer,
  Welcome,
  About,
  Features,
  Screenshots,
  Clients,
  FAQ,
  Pricing,
  Contact,
} from '../themes/MobileApp/containers';
import ReactGA from "react-ga4";

import preview from '../themes/MobileApp/assets/preview.png';

import SEO from '../components/SEO';

const Landing1 = ({ url }) =>
  {
  ReactGA.initialize('G-93ZLNYD8X1')
  ReactGA.send("pageview"); 
  return (
    <Theme>

      <Helmet>
        <link href={theme.typography.googleFont} rel="stylesheet" />
        <meta name="theme-color" content={theme.colors.primary} />
        <meta property="og:image" content={`${url}${preview}`} />
        {/* <script src="http://getlaunchlist.com/js/widget.js"></script> */}
      </Helmet>
      <SEO title="Every mile counts" />

      <Sticky style={{ zIndex: 999, position: 'relative' }}>
        <Navbar />
      </Sticky>
      <Welcome name="" />
      <About name="about" />
      <Features name="features" />
      <Screenshots name="screens" />
      {/* <Clients name="clients" />*/}
      <FAQ name="support" /> 
      <Pricing name="pricing" />
      <Contact name="waiting-list" />

      <Footer />  
      <Copyright />
    </Theme>
  );
};

Landing1.propTypes = {
  url: PropTypes.string,
};

Landing1.defaultProps = {
  url: 'https://mileradar.com',
};

export default Landing1;
