import * as Yup from "yup"
import axios from "axios"

import MapMarker from "../../icons/src/line/MapMarker"
import Phone from "../../icons/src/line/Phone"
import Envelope from "../../icons/src/line/Envelope"
import FacebookF from "../../icons/src/line/FacebookF"
import Instagram from "../../icons/src/line/Instagram"
import Youtube from "../../icons/src/line/Youtube"
import Car from "../../icons/src/line/Car"
import Wallet from "../../icons/src/line/Wallet"
import FileBlank from "../../icons/src/line/FileBlank"
import CheckCircle from "../../icons/src/line/CheckCircle"
import CheckSquare from "../../icons/src/line/CheckSquare"
import Palette from "../../icons/src/line/Palette"
import DesktopAlt from "../../icons/src/line/DesktopAlt"
import UsersAlt from "../../icons/src/line/UsersAlt"
import FileExport from "../../icons/src/line/FileExport"
import LocationArrow from "../../icons/src/line/LocationArrow"
import Shuffle from "../../icons/src/line/Shuffle"
import Medal from "../../icons/src/line/Medal"
import LightbulbAlt from "../../icons/src/line/LightbulbAlt"

import textToMultiline from "../../common/src/utils/textToMultiline"

import firstFuture from "./assets/first-feature.png"
import secondFuture from "./assets/second-feature.png"
import thirdFuture from "./assets/third-feature.svg"

import Phone1 from "./assets/screenshots/Phone-1.png"
import Phone1x2 from "./assets/screenshots/Phone-1@2x.png"
import Phone2 from "./assets/screenshots/Phone-2.png"
import Phone2x2 from "./assets/screenshots/Phone-2@2x.png"
import Phone3 from "./assets/screenshots/Phone-3.png"
import Phone3x2 from "./assets/screenshots/Phone-3@2x.png"
import Phone4 from "./assets/screenshots/Phone-4.png"
import Phone4x2 from "./assets/screenshots/Phone-4@2x.png"
import Phone5 from "./assets/screenshots/Phone-5.png"
import Phone5x2 from "./assets/screenshots/Phone-5@2x.png"
import Phone6 from "./assets/screenshots/Phone-6.png"
import Phone6x2 from "./assets/screenshots/Phone-6@2x.png"
import Phone7 from "./assets/screenshots/Phone-7.png"
import Phone7x2 from "./assets/screenshots/Phone-7@2x.png"
import Phone8 from "./assets/screenshots/Phone-8.png"
import Phone8x2 from "./assets/screenshots/Phone-8@2x.png"
import Phone9 from "./assets/screenshots/Phone-9.png"
import Phone9x2 from "./assets/screenshots/Phone-9@2x.png"

import male from "./assets/clients/photo-male.jpg"
import malex2 from "./assets/clients/photo-male@2x.jpg"
import female from "./assets/clients/photo-female.jpg"
import femalex2 from "./assets/clients/photo-female@2x.jpg"

export default {
  title: "Mobile App",
  navbar: {
    links: [
      {
        to: "",
        label: "Home",
      },
      {
        to: "about",
        label: "About",
      },
      {
        to: "features",
        label: "Features",
      },
      {
        to: "screens",
        label: "App",
      },
      {
        to: "pricing",
        label: "Pricing",
      },
    ],
    actions: [
      {
        href: "#waiting-list",
        label: "Join waiting list",
      },
    ],
  },
  welcome: {
    title: "The only mileage app you need!",
    text: `Everything that you need to properly use your car for business is now here. All the information in one app.`,
  },
  footer: {
    section1: {
      text: `We at MileRadar provide a trip management solution for your clients' business and private trips. Our app aims to make trip and expenses logging easier and flexible than ever.`,
    },
    contact: {
      title: "Contact",
      sections: [
        {
          icon: MapMarker,
          text: textToMultiline(
            `David Ricardostraat 302\nAmsterdam, 1066LH\n Noord-Holland, Netherlands`
          ),
        },
        {
          icon: Phone,
          text: "+31 75 207 0069",
        },
        {
          icon: Envelope,
          text: "hello@mileradar.com",
        },
      ],
    },
    section3: {
      title: "Coming soon!",
      text: `We are working hard on the application. We'll be ready soon. Upvote on Product Hunt and stay tuned!`,
    },
  },
  about: {
    title: "What does MileRadar do?",
    text: `We at MileRadar provide a trip management solution for your clients' business and private trips. Our app aims to make trip and expenses logging easier and flexible than ever.`,
    features: [
      {
        id: "feature1",
        IconProps: {
          icon: Car,
          gradient: "tertiary",
        },
        title: "Track your trips",
        text: "We automatically track all of your trips and save those digitally using an app. You select if it is for business or personal.",
      },
      {
        id: "feature2",
        IconProps: {
          icon: Wallet,
          gradient: "primary",
        },
        title: "Track your expenses",
        text: "We collect all of your vehicle expenses at one place. You just upload a picture of the receipt and fill in the details.",
      },
      {
        id: "feature3",
        IconProps: {
          icon: CheckSquare,
          gradient: "quaternary",
        },
        title: "Government compliant",
        text: "We make sure that your data is available in a format that is compliant to the government standard and approved by your accountant.",
      },
      {
        id: "feature4",
        IconProps: {
          icon: Palette,
          gradient: "quaternary",
        },
        title: "Make it your own",
        text: "The system offers vendor-based logos and colors that will match your company and make your dashboard more appealing.",
      },
      {
        id: "feature5",
        IconProps: {
          icon: DesktopAlt,
          gradient: "quaternary",
        },
        title: "Multi platform",
        text: "Access and manage your reports and trips through the web and mobile application",
      },
      {
        id: "feature6",
        IconProps: {
          icon: Shuffle,
          gradient: "quaternary",
        },
        title: "Flexibility",
        text: "Suitable for self-employed and companies that want to keep track of their trips and expenses.",
      },
    ],
  },
  socialLinks: [
    // {
    //   icon: FacebookF,
    //   href: 'https://mypags.app',
    //   title: 'Facebook',
    // },
    // {
    //   icon: Instagram,
    //   href: 'https://mypags.app',
    //   title: 'Instagram',
    // },
    // {
    //   icon: Youtube,
    //   href: 'https://mypags.app',
    //   title: 'YouTube',
    // },
  ],
  features: [
    {
      ImageProps: {
        src: firstFuture,
      },
      title: "Track your trips",
      text: "With our application you can easily track your business and personal trips.",
      list: [
        {
          IconProps: {
            icon: CheckCircle,
            gradient: "quaternary",
          },
          title: "Automatic tracking",
          text: "The application can automatically track your trips and keep it in a convenient place for ease of access.",
        },
        {
          IconProps: {
            icon: CheckCircle,
            gradient: "quaternary",
          },
          title: "Manual entries",
          text: "You can also add your own entries to the application.",
        },
        {
          IconProps: {
            icon: CheckCircle,
            gradient: "quaternary",
          },
          title: "Reimbursement",
          text: "The application shows you the reimbursement you will get from your trip.",
        },
      ],
    },
    {
      ImageProps: {
        src: secondFuture,
      },
      title: "Log your expenses",
      text: "We make logging expenses easier and more efficient. You can fill details, add notes to the expenses and upload invoices that will be later send to your accountant.",
      list: [
        {
          IconProps: {
            icon: CheckCircle,
            gradient: "quaternary",
          },
          title: "Add expenses",
          text: "Add all of the information you need to log your expenses.",
        },
        {
          IconProps: {
            icon: CheckCircle,
            gradient: "quaternary",
          },
          title: "Types of expenses",
          text: "You can log expenses for parking, services, tanking and etc.",
        },
        {
          IconProps: {
            icon: CheckCircle,
            gradient: "quaternary",
          },
          title: "Upload invoices",
          text: "Upload invoices and receipts to the application and they will be sent automatically to your accountant.",
        },
      ],
    },
    {
      title: "Manage your data",
      text: "The power of automated tracking and reporting reduces the manual input required from you. It enables you to track all your expenses in one place, for you and your company. Create exports of the data present in the application without any difficult excel tables for you and your employees.",
      list: [
        {
          IconProps: {
            icon: UsersAlt,
            gradient: "primary",
          },
          title: "Team management",
          text: "Be the person responsible for your team. Make sure they all have the same application base so you can easily manage your team data and vehicles. One of the major benefits: all needed data in one place.",
        },
        {
          IconProps: {
            icon: FileExport,
            gradient: "secondary",
          },
          title: "Create reports",
          text: "Take charge of your data. You can export your data in a CSV file, Excell or in a PDF file.",
        },
        {
          IconProps: {
            icon: LocationArrow,
            gradient: "tertiary",
          },
          title: "Send data directly to your accountant",
          text: "Are you tired of sending emails with invoices to your accountant? Our application sends the collected data directly to your accountant.",
        },
      ],
    },
    {
      ImageProps: {
        src: thirdFuture,
      },
      title: "",
      text: "Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.",
      list: [],
    },
  ],
  screenshots: {
    title: "The app",
    text: "",
    images: [
      {
        src: Phone1,
        srcSet: `${Phone1} 1x, ${Phone1x2} 2x`,
        alt: "Phone 1",
      },
      {
        src: Phone2,
        srcSet: `${Phone2} 1x, ${Phone2x2} 2x`,
        alt: "Phone 2",
      },
      {
        src: Phone3,
        srcSet: `${Phone3} 1x, ${Phone3x2} 2x`,
        alt: "Phone 3",
      },
      {
        src: Phone4,
        srcSet: `${Phone4} 1x, ${Phone4x2} 2x`,
        alt: "Phone 4",
      },
      {
        src: Phone5,
        srcSet: `${Phone5} 1x, ${Phone5x2} 2x`,
        alt: "Phone 5",
      },
      {
        src: Phone6,
        srcSet: `${Phone6} 1x, ${Phone6x2} 2x`,
        alt: "Phone 6",
      },
      {
        src: Phone7,
        srcSet: `${Phone7} 1x, ${Phone7x2} 2x`,
        alt: "Phone 7",
      },
      {
        src: Phone8,
        srcSet: `${Phone8} 1x, ${Phone8x2} 2x`,
        alt: "Phone 8",
      },
      {
        src: Phone9,
        srcSet: `${Phone9} 1x, ${Phone9x2} 2x`,
        alt: "Phone 9",
      },
    ],
  },
  clients: {
    title: "What clients say about us?",
    comments: [
      {
        author: "Pat Cooper, Big Company SEO",
        text: `Elit officia consectetur mollit occaecat incididunt sunt labore ad est veniam cupidatat tempor. Deserunt veniam nostrud est ad enim labore sit dolore amet enim veniam ipsum. Ullamco cillum ad nulla quis aliquip fugiat veniam incididunt ipsum cupidatat. Lorem qui voluptate ut enim occaecat et ut eu aliquip culpa.`,
        AvatarProps: {
          src: male,
          srcSet: `${male} 1x, ${malex2} 2x`,
        },
      },
      {
        author: "Orsola Jeroch, Bigger Company CTO",
        text: `Praesent nec commodo arcu. Vivamus convallis pretium malesuada. Aenean feugiat maximus suscipit. Fusce maximus aliquam ipsum, at hendrerit augue aliquet ac. Maecenas scelerisque, massa a fringilla imperdiet, velit purus gravida dolor, et blandit lorem nulla non eros.`,
        AvatarProps: {
          src: female,
          srcSet: `${female} 1x, ${femalex2} 2x`,
        },
      },
    ],
  },
  faq: {
    title: "Do you have any questions?",
    text: "We tried to answer the most common questions below, but in case we missed something please email us at hello@mileradar.com",
    options: [
      // {
      //   title: "How is my data protected?",
      //   text: "Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.",
      // },
      {
        title: "Can I turn off the automatic tracking?",
        text: "The automatic tracking is always optional. We advise users to make use of it because it will reduce the manual trip entries. The automatic tracking will create a trip entry for you will all the required data.",
      },
      {
        title: "Where is my data going to be saved?",
        text: "The data is saved to a dedicated could storage. That provides access and write speed to assure you as a user will not suffer any data loss. As expected, the storage is unlimited to be able to keep all your trip and expenses entries, reports, and history there.",
      },
      {
        title: "I have entered wrong data. What shall I do? Can I fix it?",
        text: "You can always find the selected record in the history and make the changes accordingly.",
      },
      {
        title:
          "Does my accountant also have access to my personal trips and expenses?",
        text: "You are in control which type of data you can send to your accountant. That option can be changed from your personal account settings.",
      },
      {
        title: "Can I decide when the data to be sent over to the accountant?",
        text: "As a user you can manage the time span that your data will be collected. You have the option to choose period, or you can send the data manually.",
      },
      {
        title: "Can I change the taxation percentage of the invoice/expense?",
        text: "On every newly created invoice one can select the amount of the VAT charged from the entered amount.",
      },
      {
        title:
          "I am switching to a new accountant. What shall I do? Can I transfer a history of my data?",
        text: "Your history will always be accessible from the cloud. If your accountant supports MileRadar the only action that needs to take place is changing your accountant email address.",
      },
    ],
  },
  pricing: {
    title: "Pricing",
    prices: {
      currency: "EUR",
      types: {
        a: "Monthly",
        b: "Annually",
      },
      sections: [
        {
          title: "Free forever",
          price: {
            a: "€0",
            b: "€0",
          },
          features: [
            {
              text: "Automatic tracking",
              check: true,
            },
            {
              text: "Max of 20 repots (ever)",
              check: true,
            },
            {
              text: "Max 20 trips per month",
              check: true,
            },
            {
              text: "Max 4 expenses per month",
              check: true,
            },
            {
              text: "Max 1 vehicle",
              check: true,
            },
            {
              text: "Max 5 addresses",
              check: true,
            },
            {
              text: "No email sending",
              check: false,
            },
            {
              text: "No vendor logos and colors",
              check: false,
            },
            {
              text: "No team management",
              check: false,
            },
          ],
          button: {
            text: "Sign up",
            ButtonProps: {
              as: "a",
              href: "#",
            },
          },
        },
        {
          title: "Self-employed",
          price: {
            a: "€9.95",
            b: "€99.95",
          },
          features: [
            {
              text: "Automatic tracking",
              check: true,
            },
            {
              text: "Unlimited reports",
              check: true,
            },
            {
              text: "Unlimited trips",
              check: true,
            },
            {
              text: "Unlimited expenses",
              check: true,
            },
            {
              text: "Unlimited vehicles",
              check: true,
            },
            {
              text: "Unlimited addresses",
              check: true,
            },
            {
              text: "Automatic email sending",
              check: true,
            },
            {
              text: "No vendor logos and colors",
              check: false,
            },
            {
              text: "No team management",
              check: false,
            },
          ],
          button: {
            text: "Sign up",
            ButtonProps: {
              as: "a",
              href: "#",
            },
          },
        },
        {
          title: "Company / per user",
          price: {
            a: "€9.95",
            b: "€99.95",
          },
          features: [
            {
              text: "Automatic tracking",
              check: true,
            },
            {
              text: "Unlimited reports",
              check: true,
            },
            {
              text: "Unlimited trips",
              check: true,
            },
            {
              text: "Unlimited expenses",
              check: true,
            },
            {
              text: "Unlimited vehicles",
              check: true,
            },
            {
              text: "Unlimited addresses",
              check: true,
            },
            {
              text: "Automatic email sending",
              check: true,
            },
            {
              text: "Vendor logos and colors",
              check: true,
            },
            {
              text: "Team management",
              check: true,
            },
          ],
          button: {
            text: "Sign up",
            ButtonProps: {
              as: "a",
              href: "#",
            },
          },
        },
      ],
    },
  },
  contact: {
    title: "Join Waiting List",
    text: "Just waiting list and receive 25% off your first 3 months!",
    sendButtonText: "Join now",
    form: {
      validationSchema: Yup.object({
        name: Yup.string()
          .min(1, "Must be at least 1 character")
          .max(255, "Must be 255 characters or less")
          .required("Required"),
        email: Yup.string().email("Must be an email").required("Required"),
      }),
      // eslint-disable-next-line no-undef
      onSubmit: values => {
        document.querySelector(".response-controller").innerHTML = ""
        axios
          .post("https://getlaunchlist.com/s/nVIr5j/", {
            name: values.name,
            email: values.email,
          })
          .then(response => {
            if (typeof window !== "undefined") {
              window
                .open(
                  `https://getlaunchlist.com/s/nVIr5j/${values.email}?confetti=fire`,
                  "_blank"
                )
                .focus()
              let confirm_message = "Thank you for joining!"
              document.querySelector(".response-controller").innerHTML =
                confirm_message
            }
          })
          .catch(error => {
            if (typeof window !== "undefined") {
              window
                .open(
                  `https://getlaunchlist.com/s/nVIr5j/${values.email}?confetti=fire`,
                  "_blank"
                )
                .focus()
              let confirm_message = "Thank you for joining!"
              document.querySelector(".response-controller").innerHTML =
                confirm_message
            }
          })
      },
      fields: [
        {
          name: "name",
          placeholder: "Jane Doe",
          initialValue: "",
        },
        {
          name: "email",
          placeholder: "jane@doe.com",
          type: "email",
          initialValue: "",
        },
      ],
    },
  },
  newsletter: {
    title: "Stay always up to date",
    text: "Sign up to our monthly newsletter and get news and grest special offers!",
    buttonText: "Sign up",
    inputPlaceholder: "Your email address",
  },
  copyright: `© ${new Date().getFullYear()} MileRadar - All rights reserved - Made with ❤️ in Amsterdam`,
}
