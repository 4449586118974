import background from './assets/background.svg';

export const space = [0, 4, 8, 16, 32, 64];

export const colors = {
  primary: '#3E64FF',
  secondary: '#1D2C7A',
  tertiary: '#2A54FF',
  quaternary: '#1D2C7A',
  error: '#EB5757',
  success: '#27AE60',
  gray: ['#4F3F44', '#7B6F72', '#A79FA1', '#D3CFD0', '#EAE8E9', '#F4F3F4', '#FAF9F9', '#FFFFFF'],
  gradients: {
    primary: 'linear-gradient(165.57deg, #3E64FF 22.12%, #001465 77.88%)',
    secondary: 'linear-gradient(165.57deg, #1D2C7A 22.32%, #4354DD 77.88%)',
    tertiary: 'linear-gradient(165.57deg, #2A54FF 22.12%, #3E64FF 77.88%)',
    quaternary: 'linear-gradient(165.57deg, #2A54FF 22.12%, #001052 77.88%)',
  },
  svgGradients: {
    primary: {
      from: '#3E64FF',
      to: '#4354DD'
    },
    secondary: {
      from: '#1D2C7A',
      to: '#4354DD'
    },
    tertiary: {
      from: '#2A54FF',
      to: '#3E64FF'
    },
    quaternary: {
      from: '#2A54FF',
      to: '#001052'
    },
  }
};

export const typography = {
  googleFont:
    'https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500,600,700,800&display=swap',
  fontFamily: 'Rubik, sans-serif',
  color: colors.gray[0],
  sizes: {
    base: 18,
  },
};

export const body = {
  backgroundImage: `url(${background})`,
  backgroundSize: 'cover',
  backgroundColor: colors.gray[7],
  fontSize: typography.sizes.base,
  fontFamily: typography.fontFamily,
  lineHeight: 1.6,
  color: colors.gray[0],
};

export const transition = {
  base: '.2s ease-in-out',
  all: 'all .2s ease-in-out',
};

export const zIndex = {
  navbar: 1000,
  sticky: 1010,
  menuNav: 1020,
  navbarDropdown: 990,
};

export const borderRadius = {
  small: 8,
  normal: 12,
  large: 15,
  xLarge: 25,
};

export const breakpoints = ['450px', '768px', '1170px', '1440px'];
// eslint-disable-next-line prefer-destructuring
breakpoints.sm = breakpoints[0];
// eslint-disable-next-line prefer-destructuring
breakpoints.md = breakpoints[1];
// eslint-disable-next-line prefer-destructuring
breakpoints.lg = breakpoints[2];
// eslint-disable-next-line prefer-destructuring
breakpoints.xl = breakpoints[3];
